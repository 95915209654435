<template>
  <div class="page bgc-f4f4f4 wbobyeeee">
    <div class="video-show" />
    <video id="video" ref="video" class="video vjs-fluid" autoplay />
    <el-upload
      class="upload-demo"
      action="/"
      :on-change="handlechange"
      multiple
      :limit="1"
      :auto-upload="false"
      :file-list="fileList">
      <span class="change1">
      <i class="icon iconfont icon-tupian" ></i>
    </span>
    </el-upload>
    <span class="change2" @click="qiechange">
      <i class="icon iconfont icon-qiehuanshexiangtou" ></i>
    </span>
    <span class="change3" @click="toggleTorch">
      <i :class="sdt" ></i>
    </span>
    <span class="change" @click="change">
      <i class="icon iconfont icon-return" ></i>
    </span>
    <div style="display: block !important;" class="scan-tip"> {{tipMsg}} </div>
  </div>
</template>

<script>
import { BrowserMultiFormatReader } from '@zxing/library'
// import { Dialog, Notify } from 'vant'
import { mapMutations } from 'vuex'
import QrcodeDecoder from '../../../node_modules/qrcode-decoder/dist/index'
export default {
  name: 'scanCodePage',
  data () {
    return {
      loadingShow: false,
      codeReader: null,
      scanText: '',
      vin: null,
      scanning: false,
      tipMsg: this.$t('All.年益QC系统正在尝试识别'),
      sdt: 'icon iconfont icon-shoudiantong_guan',
      fileList: []
    }
  },
  created () {
    this.codeReader = new BrowserMultiFormatReader()
    this.openScan()
    console.log(this.factory)
  },
  destroyed () {
    this.codeReader.reset()
  },
  props: ['Iscoder', 'Materialadddata', 'Numbers', 'gongdan', 'Getxwuliao', 'factory', 'Getipqcexceptiontdatalist'], // 接手psMsg值
  methods: {
    ...mapMutations(['Qcrode']),
    toggleTorch () {
      try {
        const track = this.$refs.video.srcObject.getVideoTracks()[0]
        if (track) {
          track.applyConstraints({
            focusMode: 'continuous' // 连续自动对焦
          })
        }
        const capabilities = track.getCapabilities()

        if ('torch' in capabilities) {
          if (track.getSettings().torch === false) {
            track.applyConstraints({ advanced: [{ torch: true }] })
              .then(() => {
                // 手电筒已打开
                this.sdt = 'icon iconfont icon-shoudiantong_kai'
              })
              .catch((error) => {
                alert('Error toggling torch:', error)
              })
          } else {
            track.applyConstraints({ advanced: [{ torch: false }] })
              .then(() => {
                // 手电筒已关闭
                this.sdt = 'icon iconfont icon-shoudiantong_guan'
              })
              .catch((error) => {
                alert('Error toggling torch:', error)
              })
          }
        } else {
          alert(this.$t('All.您的设备不支持'))
        }
      } catch (error) {
        alert(error)
      }
    },
    async openScan () {
      this.codeReader.getVideoInputDevices().then((videoInputDevices) => {
        this.tipMsg = this.$t('All.正在调用摄像头')
        // 默认获取第一个摄像头设备id
        let firstDeviceId = videoInputDevices[0].deviceId
        // 获取第一个摄像头设备的名称
        const videoInputDeviceslablestr = JSON.stringify(videoInputDevices[0].label)
        if (videoInputDevices.length > 1) {
          // 判断是否后置摄像头
          if (videoInputDeviceslablestr.indexOf('back') > -1) {
            firstDeviceId = videoInputDevices[0].deviceId
          } else {
            firstDeviceId = videoInputDevices[1].deviceId
          }
        }
        this.decodeFromInputVideoFunc(firstDeviceId)
      }).catch(() => {
        this.tipMsg = this.$t('All.调用摄像头失败')
        return this.$message.error(this.$t('All.您设备上可能没有摄像头'))
        // console.error(err)
      })
    },
    decodeFromInputVideoFunc (firstDeviceId) {
      if (this.scanning) return // Prevent multiple scans simultaneously
      this.scanning = true
      this.codeReader.reset() // 重置
      this.scanText = ''
      this.codeReader.decodeFromInputVideoDeviceContinuously(firstDeviceId, 'video', (result, err) => {
        this.tipMsg = this.$t('All.年益QC系统正在尝试识别')
        this.scanText = ''
        if (result) {
          // 关闭扫码功能
          this.codeReader.reset()
          this.codeReader.stopContinuousDecode()
          this.scanText = result.text
          this.onDecode(result.text)
        }
        if (err && !(err)) {
          this.tipMsg = this.$t('All.识别失败')
          setTimeout(() => {

          }, 2000)
          console.error(err)
        }
      })
    },
    qiechange () {
      alert(this.$t('All.暂不开放'))
    },
    handlechange (file) {
      const urlfile = this.$PublicJs.getObjectURL(file.raw)
      var qr = new QrcodeDecoder()
      qr.decodeFromImage(urlfile).then((res) => {
        if (res.data) {
          this.onDecode(res.data)
          this.fileList = []
        } else {
          alert(this.$t('All.系统无法识别图中二维码'))
          this.fileList = []
        }
      })
    },
    clickIndexLeft () { // 返回上一页
      this.codeReader.reset()
      this.codeReader.stopContinuousDecode()
      this.codeReader = null
      this.$destroy()
      this.$router.back()
    },
    change () {
      this.codeReader.reset()
      this.codeReader.stopContinuousDecode()
      this.codeReader = null
      this.Iscoder('')
    },
    async onDecode (row) {
      if (row.length === 31) {
        this.Getipqcexceptiontdatalist(row, this.Factory)
        return
      }
      const listdata = row.split('/')
      if (listdata.length === 6) {
        if (this.Getxwuliao(listdata[5])) {
          this.scanning = false
          this.codeReader = new BrowserMultiFormatReader()
          this.openScan()
          return
        }
        if (listdata[4] === 'CT') {
          const { data: res } = await this.$http.get(`/api/Semifinished/GetCBCTClasss?Serialcode=${listdata[5]}&gongdan=${this.gongdan}`)
          if (res.status !== 200) {
            this.scanning = false
            this.codeReader = new BrowserMultiFormatReader()
            this.openScan()
            return this.$message.error(res.msg)
          }
          if (!res.success) {
            this.scanning = false
            this.codeReader = new BrowserMultiFormatReader()
            this.openScan()
            return this.$message.error(this.$t('All.物料错误'))
          }
          const contr = {
            Numbers: this.Numbers,
            Index: 0,
            Quantity: res.response.DScsl,
            Item_no: res.response.Item_no,
            IQCNumbers: res.response.Numbers,
            Material_name: res.response.Product_name,
            Supplier: this.$t('All.自制'),
            Feeding_date: res.response.Cdate,
            Person: res.response.Czu,
            Lot_no: res.response.Lot,
            Name: 'Pass',
            Serialcode: res.response.Serialcode,
            Type: 3,
            Order_no: this.gongdan,
            User: this.$store.state.Login.User,
            Username: this.$store.state.Login.Username,
            Factory: this.$store.state.Login.Factory,
            State: 0,
            Specifications: res.response.Gg,
            Unit: 'KG'
          }
          this.Materialadddata(contr)
          this.scanning = false
          this.codeReader = new BrowserMultiFormatReader()
          this.openScan()
          return
        }
        if (listdata[4] === 'CY') {
          const { data: res } = await this.$http.get(`/api/Semifinished/GetCBCYClasss?Serialcode=${listdata[5]}&gongdan=${this.gongdan}`)
          if (res.status !== 200) {
            this.scanning = false
            this.codeReader = new BrowserMultiFormatReader()
            this.openScan()
            return this.$message.error(res.msg)
          }
          if (!res.success) {
            this.scanning = false
            this.codeReader = new BrowserMultiFormatReader()
            this.openScan()
            return this.$message.error(this.$t('All.物料错误'))
          }
          const contr = {
            Numbers: this.Numbers,
            Index: 0,
            Quantity: res.response.DScsl,
            Item_no: res.response.Item_no,
            IQCNumbers: res.response.Numbers,
            Material_name: res.response.Product_name,
            Supplier: this.$t('All.自制'),
            Feeding_date: res.response.Rdate,
            Person: res.response.Czu,
            Lot_no: res.response.Lot,
            Name: 'Pass',
            Serialcode: res.response.Serialcode,
            Type: 5,
            Order_no: this.gongdan,
            User: this.$store.state.Login.User,
            Username: this.$store.state.Login.Username,
            Factory: this.$store.state.Login.Factory,
            State: 0,
            Specifications: res.response.Gg,
            Unit: 'PC'
          }
          this.Materialadddata(contr)
          this.scanning = false
          this.codeReader = new BrowserMultiFormatReader()
          this.openScan()
          return
        }
        if (listdata[4] === 'ER') {
          const { data: res } = await this.$http.get(`/api/Semifinished/GetCBERClasss?Serialcode=${listdata[5]}&gongdan=${this.gongdan}`)
          if (res.status !== 200) {
            this.scanning = false
            this.codeReader = new BrowserMultiFormatReader()
            this.openScan()
            return this.$message.error(res.msg)
          }
          if (!res.success) {
            this.scanning = false
            this.codeReader = new BrowserMultiFormatReader()
            this.openScan()
            return this.$message.error(this.$t('All.物料错误'))
          }
          const contr = {
            Numbers: this.Numbers,
            Index: 0,
            Quantity: res.response.DScsl,
            Item_no: res.response.Item_no,
            IQCNumbers: res.response.Numbers,
            Material_name: res.response.Product_name,
            Supplier: this.$t('All.自制'),
            Feeding_date: res.response.Cdate,
            Person: res.response.Jyy,
            Lot_no: res.response.Lot,
            Name: 'Pass',
            Serialcode: res.response.Serialcode,
            Type: 2,
            Order_no: this.gongdan,
            User: this.$store.state.Login.User,
            Username: this.$store.state.Login.Username,
            Factory: this.$store.state.Login.Factory,
            State: 0,
            Specifications: res.response.Gg,
            Unit: res.response.Dw
          }
          this.Materialadddata(contr)
          this.scanning = false
          this.codeReader = new BrowserMultiFormatReader()
          this.openScan()
          return
        }
        if (listdata[4] === 'ZL') {
          const { data: res } = await this.$http.get(`/api/Semifinished/GetCBZLClasss?Serialcode=${listdata[5]}&gongdan=${this.gongdan}`)
          if (res.status !== 200) {
            this.scanning = false
            this.codeReader = new BrowserMultiFormatReader()
            this.openScan()
            return this.$message.error(res.msg)
          }
          if (!res.success) {
            this.scanning = false
            this.codeReader = new BrowserMultiFormatReader()
            this.openScan()
            return this.$message.error(this.$t('All.物料错误'))
          }
          const contr = {
            Numbers: this.Numbers,
            Index: 0,
            Quantity: res.response.DZl,
            Item_no: res.response.Item_no,
            IQCNumbers: res.response.Numbers,
            Material_name: res.response.Product_name,
            Supplier: this.$t('All.自制'),
            Feeding_date: res.response.Cdate,
            Person: res.response.Jyy,
            Lot_no: res.response.Lot,
            Name: 'Pass',
            Serialcode: res.response.Serialcode,
            Type: 4,
            Order_no: this.gongdan,
            User: this.$store.state.Login.User,
            Username: this.$store.state.Login.Username,
            Factory: this.$store.state.Login.Factory,
            State: 0,
            Specifications: res.response.Gg,
            Unit: 'KG'
          }
          this.Materialadddata(contr)
          this.scanning = false
          this.codeReader = new BrowserMultiFormatReader()
          this.openScan()
          return
        }
        if (listdata[4] === 'SelPrint') {
          const { data: res } = await this.$http.get(`/api/Semifinished/GetXbcsjk?Serialcode=${listdata[5]}&gongdan=${this.gongdan}`)
          if (res.status !== 200) {
            this.scanning = false
            this.codeReader = new BrowserMultiFormatReader()
            this.openScan()
            return this.$message.error(res.msg)
          }
          if (!res.success) {
            this.scanning = false
            this.codeReader = new BrowserMultiFormatReader()
            this.openScan()
            return this.$message.error(this.$t('All.物料错误'))
          }
          const contr = {
            Numbers: this.Numbers,
            Index: 0,
            Quantity: res.response.Zong,
            Item_no: res.response.ItemNo,
            IQCNumbers: res.response.Number,
            Material_name: res.response.Name,
            Supplier: this.$t('All.自制'),
            Feeding_date: res.response.Date,
            Person: res.response.Username,
            Lot_no: res.response.Lot,
            Name: 'Pass',
            Serialcode: res.response.Tsmpnumber,
            Type: 1,
            Order_no: this.gongdan,
            User: this.$store.state.Login.User,
            Username: this.$store.state.Login.Username,
            Factory: this.$store.state.Login.Factory,
            State: 0,
            Specifications: res.response.Gg,
            Unit: 'M'
          }
          this.Materialadddata(contr)
          this.scanning = false
          this.codeReader = new BrowserMultiFormatReader()
          this.openScan()
          return
        }
        if (listdata[4] === 'Pass') {
          this.Subcontractset(listdata[5])
          return
        }
      }
      if (row.length === 29) {
        this.Subcontractset(row)
        return
      }
      const strArr = row.split('#')
      if (strArr.length === 11) {
        this.Subcontractset(strArr[9])
        return
      }
      alert(this.$t('All.此二维码非系统二维码') + '，' + this.$t('All.内容') + '：' + row)
      this.scanning = false
      this.codeReader = new BrowserMultiFormatReader()
      this.openScan()
    },
    async Subcontractset (row) {
      if (this.Getxwuliao(row)) {
        this.scanning = false
        this.codeReader = new BrowserMultiFormatReader()
        this.openScan()
        return
      }
      const { data: res } = await this.$http.post(`/api/Trace/Subcontractsets?Serialcode=${row}&gongdan=${this.gongdan}`)
      if (res.status !== 200) {
        this.scanning = false
        this.codeReader = new BrowserMultiFormatReader()
        this.openScan()
        return this.$message.error(res.msg)
      }
      if (!res.success) {
        this.scanning = false
        this.codeReader = new BrowserMultiFormatReader()
        this.openScan()
        return this.$message.error(this.$t('All.物料错误'))
      }
      const contr = {
        Numbers: this.Numbers,
        Index: 0,
        Quantity: res.response.Boxquantity,
        Item_no: res.response.Item_No,
        IQCNumbers: res.response.Numbers,
        Material_name: res.response.Product_name,
        Supplier: res.response.Supplier,
        Feeding_date: res.response.Time,
        Person: res.response.Operator,
        Lot_no: res.response.Order_Number,
        Name: 'Pass',
        Serialcode: res.response.Serialcode,
        Type: 0,
        Order_no: this.gongdan,
        User: this.$store.state.Login.User,
        Username: this.$store.state.Login.Username,
        Factory: this.$store.state.Login.Factory,
        State: 0,
        Specifications: res.response.Pddsc2,
        Unit: res.response.Company
      }
      this.Materialadddata(contr)
      this.scanning = false
      this.codeReader = new BrowserMultiFormatReader()
      this.openScan()
    }
  }
}
</script>

<style lang="less" scoped>
  body{
    overflow: hidden;
    margin:  0 auto;
  }
  .wbobyeeee{
  left:0;
  right:0;
  top:0;
  bottom:0;
  margin:auto;
  position: fixed;
  height: 100%;
  width: 100%;
  overflow: hidden !important;
}
  .video {
    margin: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
  .video-show {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 30vw;
    height: 30vw;
    z-index: 2;
    background: linear-gradient(to left, #2dec2d, #2dec2d) left top no-repeat,
      linear-gradient(to bottom, #2dec2d, #2dec2d) left top no-repeat,
      linear-gradient(to left, #2dec2d, #2dec2d) right top no-repeat,
      linear-gradient(to bottom, #2dec2d, #2dec2d) right top no-repeat,
      linear-gradient(to left, #2dec2d, #2dec2d) left bottom no-repeat,
      linear-gradient(to bottom, #2dec2d, #2dec2d) left bottom no-repeat,
      linear-gradient(to left, #2dec2d, #2dec2d) right bottom no-repeat,
      linear-gradient(to left, #2dec2d, #2dec2d) right bottom no-repeat;
    background-size: 2px 20px, 20px 2px, 2px 20px, 20px 2px;
  }
  .tip {
    width: 10vw;
    height: 10vw;
    background-color: rgb(45, 236, 45);
    border: 1px solid #ffffff;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }

  .bgc-f4f4f4 {
    background-color: #363636;
  }

  .page {
    // position: relative;
    overflow: hidden;
    // position: relative;
    height: 100vh;
  }
  .change {
    z-index: 100;
    position: fixed;
    bottom: 40px;
    left: 73vw;
    transform: translateX(-50%);
    color: #fff;
    text-align: center;
    background-color: #282c3387;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    line-height: 50px;
    /* align-content: center; */
  }
  .el-icon-refresh {
    color: #000;
    font-size: 25px;
  }
  .scan-tip{
    position: absolute;
    left: 50vw;
    top: 9vw;
    transform: translate(-50%, -50%);
    color:#00ae10;
  }
  /********************************** */
  .change1 {
    z-index: 100;
    position: fixed;
    bottom: 40px;
    left: 28vw;
    transform: translateX(-50%);
    color: #fff;
    text-align: center;
    background-color: #282c3387;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    line-height: 50px;
    /* align-content: center; */
  }
  .change2 {
    z-index: 100;
    position: fixed;
    bottom: 40px;
    left: 43vw;
    transform: translateX(-50%);
    color: #fff;
    text-align: center;
    background-color: #282c3387;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    line-height: 50px;
    /* align-content: center; */
  }
  .change3 {
    z-index: 100;
    position: fixed;
    bottom: 40px;
    left: 58vw;
    transform: translateX(-50%);
    color: #fff;
    text-align: center;
    background-color: #282c3387;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    line-height: 50px;
    /* align-content: center; */
  }
  /************************************* */
</style>
